<template>
    <el-row id="about" class="about" type="flex" justify="center">
        <el-col :span="20">
            <el-row type="flex" justify="space-between" class="row-flex">
                <el-col :md="24" :lg="10" class="intro-col">
                    <img class="intro-img" src="@/assets/images/intro/about-us.jpg" alt="">
                </el-col>
                <el-col :md="24" :lg="13" class="company-intro">
                    <h3>公司简介</h3>
                    <p>苏州版图网络科技有限公司成立于2015年，地处中国新加坡合作苏州工业园区的科技中心，是一家专业从事IT技术和人才外包的软件服务公司。</p>
                    <p>版图网络以“诚信为本、务实求己、勇于创新、开拓发展”的基本价值理念，以资源优化和技术创新为企业的发展之道，依托公司优秀的开发管理人才， 为客户提供系统的定制、开发以及解决方案的制定、技术与人才外包等方面的专业、持久的个性化服务。</p>
                    <p>公司拥有一支技术精湛和经验丰富的专业团队，现拥有20多名专业技术开发人员,在JAVA WEB、ANDRIOD 、IOS等技术平台上积累了丰富的经验。</p>
                    <p>自成立以来，与多家大中型企业合作开发信用体系，物流、银行、水利、电商等领域的软件，同时以完善的管理体系、严谨的开发流程、超高的开发质量， 获得了客户高度的认可和好评。</p>
                </el-col>
            </el-row>
            <el-row>
                <el-row><feature-head text="企业文化"/></el-row>
                <el-row type="flex" justify="space-between" class="culture-row">
                    <el-col :md="5" class="culture-item">
                        <img class="culture-item-img" src="@/assets/images/culture/culture-honesty.png" alt="">
                        <h3 class="item-title">诚信敬业</h3>
                        <p class="item-desc"></p>
                    </el-col>
                    <el-col :md="5" class="culture-item">
                        <img class="culture-item-img" src="@/assets/images/culture/culture-value.png" alt="">
                        <h3 class="item-title">为客户创造价值</h3></el-col>
                        <p class="item-desc"></p>
                    <el-col :md="5" class="culture-item">
                        <img class="culture-item-img" src="@/assets/images/culture/culture-success.png" alt="">
                        <h3 class="item-title">使员工富有成就</h3></el-col>
                        <p class="item-desc"></p>
                    <el-col :md="5" class="culture-item">
                        <img class="culture-item-img" src="@/assets/images/culture/culture-payback.png" alt="">
                        <h3 class="item-title">让合作伙伴获得厚报</h3>
                        <p class="item-desc"></p>
                    </el-col>
                </el-row>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import FeatureHead from "@/components/common/FeatureHead";
    export default {
        name: "About",
        components: {
            FeatureHead
        }
    }
</script>

<style scoped>
.about {
    font-family: "Microsoft YaHei";
}

.about .row-flex {
    padding: 30px 0 0;
    box-sizing: border-box;
}

.about .intro-col {
    display: flex;
    align-content: center;
}

.about .intro-img {
    width: 100%;
    height: auto;
}

.about .company-intro {
    line-height: 1.5;
}

.about .company-intro h3 {
    margin: 5px 0;
    font-size: 20px;
    font-weight: 400;
}

.about .company-intro p {
    color: #797979;
    text-indent: 2em;
    font-size: 14px;
}

.about .culture-row {
    flex-wrap: wrap;
}

.about .culture-item {
    margin-bottom: 30px;
    text-align: center;
}

.about .culture-item .culture-item-img {
    width: 100px;
}

.about .culture-item .item-title {
    margin: 10px 0;
    color: #475168;
    font-weight: 400;
    font-size: 14px;
}

.about .culture-item .item-desc {
    font-size: 13px;
    color: #797979;
}
</style>
